<template>
  <div id="components">
    <Shows />
  </div>
</template>

<script>

import Shows from "@/components/Shows";

export default {
  name: 'Shows-View',
  components: {Shows},
}
</script>
